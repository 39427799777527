export const arqueoDeCajaData = (data, config) => {
  if (data?.getAccountBoxPayments && config?.paymentMethods) {
    //! descontar y sumar otras transferencias
    const { moves, transfersFrom, transfersTo } = data.getAccountBoxPayments;

    // Solo para moves
    const arrayMoves = [];
    if (moves?.length > 0) {
      moves.forEach((move) => {
        move?.payment?.forEach((payment) => {
          let total = payment.total || 0;

          if (move.operation?.toUpperCase() === "EGRESO") {
            total = total * -1;
          }

          arrayMoves.push({
            ...payment,
            _id: payment?.paymentMethodId,
            name:
              config?.paymentMethods?.find(
                (method) => method?._id === payment?.paymentMethodId
              )?.name || "",
            total: total,
          });
        });
      });
    }

    let from = [];
    if (transfersFrom?.length > 0) {
      transfersFrom.forEach((transferencia) => {
        transferencia?.transferInput?.forEach((payment) => {
          from.push({
            ...payment,
            _id: payment?.paymentMethodId,
            name:
              config?.paymentMethods?.find(
                (method) => method?._id === payment?.paymentMethodId
              )?.name || "",
            total: payment.amount || 0,
          });
        });
      });
    }

    let to = [];
    if (transfersTo?.length > 0) {
      transfersTo.forEach((transferencia) => {
        transferencia?.transferInput?.forEach((payment) => {
          to.push({
            ...payment,
            _id: payment?.paymentMethodId,
            name:
              config?.paymentMethods?.find(
                (method) => method?._id === payment?.paymentMethodId
              )?.name || "",
            total: payment.amount || 0,
          });
        });
      });
    }

    let metodos = {};
    // Moves
    metodos = getGroupedByPaymentMethod(arrayMoves, metodos, false);

    // From
    metodos = getGroupedByPaymentMethod(from, metodos, true);

    // To
    metodos = getGroupedByPaymentMethod(to, metodos, false);

    const definitivo = Object.values(metodos);

    return definitivo || [];
  }

  return [];
};

const getGroupedByPaymentMethod = (array, previous, restar) => {
  const resultado = array?.reduce((result, item) => {
    const { name, _id } = item;
    if (!result[name]) {
      let total = 0;
      if (restar) {
        total = item.total * -1;
      } else {
        total = item.total;
      }

      result[name] = {
        total: total || 0,
        _id,
        name,
      };
    } else {
      if (restar) {
        let total = result[name].total - item.total;

        result[name].total = total;
      } else {
        let total = result[name].total + item.total;

        result[name].total = total;
      }
    }

    return result;
  }, previous);

  return resultado || [];
};

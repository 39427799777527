import { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { GlobalContext } from "../context/GlobalContext";
import { BlobLoader } from "../ui/loader/BlobLoader";
import { handleUser } from "../../utils/handleUser";
import { isAvailable } from "../../utils/isAvailable";
import { SECCION_PERMISOS } from "../../assets/permisos-template";

export const PrivateRouter = ({ children, seccion }) => {
  const { user, setUser } = useContext(GlobalContext);

  const [isLoading, setIsLoading] = useState(true);

  const checkIsAvailable = () => {
    if (seccion) {
      return isAvailable(
        seccion,
        SECCION_PERMISOS.modulo,
        user?.rol?.permissions
      );
    }

    return true;
  };

  useEffect(() => {
    if (user?._id) {
      setIsLoading(false);
    } else {
      setUser(handleUser());
      setIsLoading(false);
    }
  }, [user]);

  if (isLoading) return <BlobLoader />;

  if (!user?.rol || user === null || !checkIsAvailable()) {
    return <Navigate to="/login" />;
  }

  if (user.type?.toLowerCase() === "system") {
    return children;
  }
  if (user.type?.toLowerCase() === "student") {
    return <Navigate to="/student" />;
  }
  if (user.type?.toLowerCase() === "profesor") {
    return <Navigate to="/profesor" />;
  }

  return children;
};

import { gql } from "@apollo/client";

export const GET_AB_CONCEPTS = gql`
  query getAccountBoxPayments($abId: String) {
    getAccountBoxPayments(abId: $abId) {
      moves {
        _id
        operation
        total
        createdAt
        concept {
          _id
          name
        }
        area {
          _id
          name
        }
        payment {
          paymentMethodId
          total
        }
      }
      transfersFrom {
        abFrom
        abTo
        transferInput {
          paymentMethodId
          amount
        }
        total
        createdAt
        userId
      }
      transfersTo {
        abFrom
        abTo
        transferInput {
          paymentMethodId
          amount
        }
        total
        createdAt
        userId
      }
    }
  }
`;

export const GET_TRANSFERS = gql`
  query getTransfersResolver {
    getTransfersResolver {
      _id
      abFrom
      abTo
      transferInput {
        paymentMethodId
        amount
      }
      createdAt
      total
      userId
    }
  }
`;

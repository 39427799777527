import { Modal, Table, Tag, Tooltip, Button, Drawer } from "antd";
import dayjs from "dayjs";
import { FiX } from "react-icons/fi";
import { thousandFormatter } from "../../../../../utils/thousandFormatter";
import { useContext, useEffect, useMemo, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_ACCOUTING_BOXES_HISTORY } from "../../../../../graphql/query/Cajas";
import { DetalleCierre } from "./DetalleCierre";
import { TbListDetails } from "react-icons/tb";
import { ArqueoDeCaja } from "../../../reports/components/cierreCaja/ArqueoDeCaja";
import { PDFViewer } from "@react-pdf/renderer";
import { GET_AB_CONCEPTS } from "../../../../../graphql/query/Transferencias";
import { GlobalContext } from "../../../../context/GlobalContext";
import { arqueoDeCajaData } from "../../../../../utils/arqueoDeCajaData";

const columns = (setSelectedCierre) => {
  return [
    {
      title: "Fecha de apertura",
      dataIndex: "dateFrom",
      key: "dateFrom",
      width: 180,
      render: (data) => {
        if (data) {
          return (
            <p className="number-ff">{dayjs(data, "x").format("DD/MM/YYYY")}</p>
          );
        } else {
          return false;
        }
      },
    },
    {
      title: "Fecha de cierre",
      dataIndex: "dateTo",
      key: "dateTo",
      width: 180,
      render: (data) => {
        if (data) {
          return (
            <p className="number-ff">{dayjs(data, "x").format("DD/MM/YYYY")}</p>
          );
        } else {
          return false;
        }
      },
    },
    {
      title: "Estado",
      dataIndex: "status",
      key: "status",
      render: (data) => (
        <Tag color={data === "OPEN" ? "green" : "volcano"}>
          {data === "OPEN" ? "Abierto" : "Cerrado"}
        </Tag>
      ),
    },
    {
      title: "Saldo",
      dataIndex: "amount",
      key: "amount",
      align: "right",
      render: (data) => (
        <p className="number-ff" style={{ color: "#00A150" }}>
          {thousandFormatter(data)}
        </p>
      ),
    },
    {
      title: "Acciones",
      dataIndex: "",
      key: "",
      align: "center",
      render: (data, item) => (
        <span>
          <Tooltip title="Ver detalle">
            <span
              className="pointer"
              onClick={() => {
                setSelectedCierre(item);
              }}
            >
              <TbListDetails />
            </span>
          </Tooltip>
        </span>
      ),
    },
  ];
};

export const ModalCajas = ({ cajaData, setCajaData }) => {
  const { config } = useContext(GlobalContext);

  const [
    getABHistoryResolver,
    { data: dataAB, loading: loadingAB, error: errorAB },
  ] = useLazyQuery(GET_ACCOUTING_BOXES_HISTORY, {
    fetchPolicy: "network-only",
  });

  const [abConceptsResolver, { data: dataPayments, loading, error }] =
    useLazyQuery(GET_AB_CONCEPTS, {
      fetchPolicy: "network-only",
    });

  const [selectedCierre, setSelectedCierre] = useState(null);

  const [listaCierres, setListaCierres] = useState([]);
  const [showDrawer, setShowDrawer] = useState(false);

  useEffect(() => {
    if (cajaData?.item?._id && selectedCierre === null) {
      getABHistoryResolver({
        variables: { accountBoxId: cajaData.item?._id },
      });
    }
  }, [cajaData, selectedCierre]);

  const getFormattedList = (list) => {
    let array = [...list]?.sort((a, b) => b.dateFrom || 0 - a.dateFrom || 0);

    let firstElement = array?.[0];

    if (firstElement?.status?.toUpperCase() === "CLOSE" || array.length === 0) {
      let dateTo = firstElement?.dateTo;

      let obj = {
        accountBoxId: cajaData.item?._id,
        dateFrom: dateTo || null,
        dateTo: null,
        status: "OPEN",
        amount: null,
        _id: Date.now(),
      };

      array = [obj, ...list];
    }

    return array;
  };

  useEffect(() => {
    if (cajaData?.item?._id) {
      abConceptsResolver({ variables: { abId: cajaData?.item?._id } });
    }
  }, [cajaData]);

  const conceptos = useMemo(() => {
    return arqueoDeCajaData(dataPayments, config);
  }, [dataPayments, config]);

  useEffect(() => {
    if (dataAB?.getABHistoryResolver) {
      setListaCierres(getFormattedList(dataAB.getABHistoryResolver));
    }
  }, [dataAB]);

  return (
    <Modal
      open={cajaData?.open}
      className="custom-modal-wrapper"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      closeIcon={
        <FiX
          onClick={() =>
            setCajaData((prevState) => {
              return {
                ...prevState,
                open: false,
                stage: "table",
                item: null,
              };
            })
          }
        />
      }
      footer={false}
    >
      <>
        <h4>{cajaData?.item?.name}</h4>
        {!selectedCierre ? (
          <>
            <Table
              style={{
                marginTop: 20,
                width: "clamp(400px, 90vw, 1000px)",
              }}
              loading={loadingAB}
              columns={columns(setSelectedCierre)}
              dataSource={listaCierres || []}
              rowKey={"_id"}
              pagination={{
                pageSize: 8,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} de ${total} cierres`,
                showSizeChanger: false,
              }}
            />
            <Button className="btn-guardar" onClick={() => setShowDrawer(true)}>
              Arqueo de caja
            </Button>
            <Drawer
              open={showDrawer}
              width={"calc(100% - 70px)"}
              destroyOnClose
              onClose={(v) => setShowDrawer(false)}
            >
              <PDFViewer
                style={{ width: "100%", height: "100%", border: "none" }}
              >
                <ArqueoDeCaja
                  dataCierre={{ name: cajaData?.item?.name, context: "caja" }}
                  dataConceptos={conceptos}
                />
              </PDFViewer>
            </Drawer>
          </>
        ) : (
          <DetalleCierre
            selectedCierre={selectedCierre}
            setSelectedCierre={setSelectedCierre}
            setCajaData={setCajaData}
            cajaData={cajaData}
          />
        )}
      </>
    </Modal>
  );
};

import { gql } from "@apollo/client";

export const CREATE_MOVE = gql`
  mutation NewAccountingBoxMovesResolver($moveInput: moveInput) {
    newAccountingBoxMovesResolver(moveInput: $moveInput) {
      _id
      operation
      description
      date
      total
      createdAt
      concept {
        _id
        name
        description
      }
      currency {
        _id
        name
        symbol
      }
      area {
        _id
        name
        description
      }
      feeDetail {
        courseId
        fees {
          feeNumber
          value
        }
      }
      studentId
      payment {
        paymentMethodId
        total
      }
      accountBoxId
      accountingBox {
        _id
        name
      }
    }
  }
`;

export const UPDATE_MOVE = gql`
  mutation Mutation($moveId: String, $moveInput: moveInput) {
    updateMoveResolver(moveId: $moveId, moveInput: $moveInput) {
      _id
      operation
      description
      date
      total
      createdAt
      concept {
        _id
        name
        description
      }
      currency {
        _id
        name
        symbol
      }
      area {
        _id
        name
        description
      }
      feeDetail {
        courseId
        fees {
          feeNumber
          value
        }
      }
      studentId
      payment {
        paymentMethodId
        total
      }
      accountBoxId
      accountingBox {
        _id
        name
      }
    }
  }
`;

export const DELETE_MOVE = gql`
  mutation DeleteMoveResolver($moveId: String) {
    deleteMoveResolver(moveId: $moveId) {
      _id
      accountBoxId
    }
  }
`;

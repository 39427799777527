import { Button, Form, Input, message } from "antd";
import "./login.css";
import { AiOutlineUser } from "react-icons/ai";
import { RiLockPasswordLine } from "react-icons/ri";
import { useMutation } from "@apollo/client";
import { LOGIN_AUTH } from "../../../graphql/mutation/Login";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { BlobLoader } from "../../ui/loader/BlobLoader.jsx";
import CryptoJS from "crypto-js";
import { useContext } from "react";
import { GlobalContext } from "../../context/GlobalContext";

export const Login = () => {
  const [form] = Form.useForm();

  const navigate = useNavigate();

  const { user, setUser } = useContext(GlobalContext);

  const [messageApi, contextHolder] = message.useMessage();
  const [showLoader, setShowLoader] = useState(false);

  const [loginAuthenticationResolver] = useMutation(LOGIN_AUTH, {
    onCompleted: (data) => {},
    onError: (error) =>
      messageApi.error("Ocurrió un error al intentar ingresar al sistema"),
  });

  const passphrase = process.env.REACT_APP_TOKEN_SECRET;

  const saveToken = (token) => {
    try {
      let tmp = JSON.parse(
        CryptoJS.AES.decrypt(token, passphrase).toString(CryptoJS.enc.Utf8)
      );

      // const token = CryptoJS.AES.encrypt(
      //   JSON.stringify(tmp),
      //   passphrase
      // ).toString();

      //! Borrar
      // tmp.type = "instructor";
      
      setUser(tmp);
      localStorage.setItem("token", token);

      if (tmp?.type?.toLowerCase() === "student") {
        navigate("/student");
      } else if (tmp?.type?.toLowerCase() === "instructor") {
        navigate("/instructor");
      } else {
        navigate("/");
      }
    } catch (error) {
      messageApi.error("No se pudo registrar el token de acceso");
      setShowLoader(false);
    }
  };

  const onFinish = (values) => {
    setShowLoader(true);
    loginAuthenticationResolver({
      variables: {
        loginAuthInput: {
          password: values.password,
          username: values.username,
        },
      },
    })
      .then((res) => {
        if (res?.data?.loginAuthenticationResolver?.status === 200) {
          saveToken(res.data.loginAuthenticationResolver?.message);
        } else {
          localStorage.removeItem("token");
          if (res?.data?.loginAuthenticationResolver?.message) {
            messageApi.error(res.data.loginAuthenticationResolver.message);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        localStorage.removeItem("token");
      })
      .finally(() => setShowLoader(false));
  };

  useEffect(() => {
    let token = localStorage.getItem("token");

    if (token) {
      navigate("/");
    }
  }, []);

  return (
    <div className="login-wrapper">
      {contextHolder}
      <img
        src="/assets/blob-scene-haikei.svg"
        alt="Imagen de fondo"
        className="background-image"
      />
      <div className="login-form">
        {!showLoader ? (
          <>
            <span className="titulo-login">Ingreso</span>
            <Form form={form} name="login-form" onFinish={onFinish}>
              <Form.Item name="username">
                <Input
                  prefix={<AiOutlineUser size={18} />}
                  placeholder="Usuario"
                  size="large"
                />
              </Form.Item>
              <Form.Item name="password">
                <Input.Password
                  visibilityToggle
                  prefix={<RiLockPasswordLine size={18} />}
                  placeholder="Contraseña"
                  size="large"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  block
                  size="large"
                  className="btn-generico"
                  htmlType="submit"
                >
                  Ingresar
                </Button>
              </Form.Item>
            </Form>
            {/* <span className="btn-group">
              <a href="#">Olvidé mi contraseña</a>
              <a href="#">Registrarme</a>
            </span> */}
          </>
        ) : (
          <BlobLoader />
        )}
      </div>
    </div>
  );
};

import { useState } from "react";
import { StudentHome } from "../components/layout/student/StudentHome";
import { CgMenu } from "react-icons/cg";
import { FiX } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { appReload } from "../utils/appReload";

export const InstructorView = () => {
  const [showMenu, setShowMenu] = useState(false);

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
    appReload();
  };

  return (
    <div className="student-view-wrapper">
      <header className="header-student">
        {!showMenu ? (
          <CgMenu className="pointer" onClick={() => setShowMenu(true)} />
        ) : (
          <>
            <FiX className="pointer" onClick={() => setShowMenu(false)} />
            <div className="student-menu-wrapper">
              <span className="student-menu-item">Mis cursos</span>
              <span
                className="student-menu-item"
                onClick={() => handleLogout()}
              >
                Cerrar sesión
              </span>
            </div>
          </>
        )}
        <p className="titulo-sistema" style={{ fontSize: 20 }}>
          CÁNADEZ
        </p>
      </header>
      <StudentHome />
    </div>
  );
};

import { gql } from "@apollo/client";

export const GET_ACCOUNTING_BOXES_BY_USER_ID = gql`
  query getAccountingBoxesByUserResolver($userId: String) {
    getAccountingBoxesByUserResolver(userId: $userId) {
      _id
      createdAt
      isActive
      accountBoxData {
        _id
        name
      }
    }
  }
`;

export const GET_ACCOUTING_BOXES_HISTORY = gql`
  query getABHistoryResolver($accountBoxId: String) {
    getABHistoryResolver(accountBoxId: $accountBoxId) {
      _id
      dateFrom
      dateTo
      status
      amount
      accountBoxId
    }
  }
`;

export const GET_MOVES_DETAIL = gql`
  query getMovesDetailResolver(
    $accountBoxId: String
    $dateFrom: Float
    $dateTo: Float
  ) {
    getMovesDetailResolver(
      accountBoxId: $accountBoxId
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      moves {
        _id
        operation
        description
        date
        total
        createdAt
        concept {
          _id
          name
          description
        }
        currency {
          _id
          name
          symbol
        }
        area {
          _id
          name
          description
        }
        feeDetail {
          courseId
          fees {
            feeNumber
            value
          }
        }
        studentId
        payment {
          paymentMethodId
          total
        }
        accountBoxId
      }
    }
  }
`;

export const GET_TRANSFER_DETAIL = gql`
  query getMovesByTransferResolver($transferId: String) {
    getMovesByTransferResolver(transferId: $transferId) {
      _id
      operation
      description
      date
      total
      createdAt
      concept {
        _id
        name
        description
      }
      currency {
        _id
        name
        symbol
      }
      area {
        _id
        name
        description
      }
      feeDetail {
        courseId
        fees {
          feeNumber
          value
        }
      }
      studentId
      payment {
        paymentMethodId
        total
      }
      accountBoxId
    }
  }
`;

import { gql } from "@apollo/client";

export const GET_CONFIG = gql`
  query GetConfigResolver {
    getConfigResolver {
      currencies {
        _id
        name
        symbol
      }
      locations {
        _id
        nombre
      }
      paymentMethods {
        _id
        name
      }
      concepts {
        _id
        description
        name
      }
      classrooms {
        _id
        maxCapacity
        name
      }
      withdrawalReasons {
        _id
        name
      }
      accountingBoxes {
        _id
        createdAt
        name
        description
      }
      areas {
        _id
        description
        name
      }
    }
  }
`;

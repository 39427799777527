import { useQuery } from "@apollo/client";
import { Button, Input, Modal, Popover, Select, Table, Tooltip } from "antd";
import { useContext } from "react";
import { useState } from "react";
import { FiEdit3, FiEye } from "react-icons/fi";
import { AiOutlineSchedule, AiOutlineSearch } from "react-icons/ai";
import { TbListDetails } from "react-icons/tb";
import { GlobalContext } from "../../context/GlobalContext";
import { BlobLoader } from "../../ui/loader/BlobLoader";
import { thousandFormatter } from "../../../utils/thousandFormatter";
import { isAvailable } from "../../../utils/isAvailable";
import { SECCION_PERMISOS } from "../../../assets/permisos-template";
import { MainModal } from "../../ui/modales/MainModal";

export const Cursos = () => {
  const {
    drawerContent,
    setDrawerContent,
    messageApi,
    cursos,
    setCursos,
    loadingGlobal,
    user,
    config,
  } = useContext(GlobalContext);

  const [idBorrar, setIdBorrar] = useState(null);

  const [showModal, setShowModal] = useState(false);

  const deleteCurso = (item) => {
    if (item._id) {
      setIdBorrar(item._id);
    }
  };

  const handleBuscarDisponibilidad = () => {
    setShowModal(true);
  };

  const ColumnaAcciones = ({ item }) => {
    const [showPopover, setShowPopover] = useState(false);

    return (
      <div className="fila-acciones">
        {isAvailable(
          "courses",
          SECCION_PERMISOS.ver,
          user?.rol?.permissions
        ) && (
          <Tooltip title="Ver detalles" placement="topRight">
            <span style={{ height: "20px" }}>
              <FiEye
                className="icono-acciones"
                onClick={(e) => {
                  e.stopPropagation();
                  setDrawerContent({
                    visible: true,
                    type: "Ver Curso",
                    item: item,
                  });
                }}
              />
            </span>
          </Tooltip>
        )}
        {isAvailable(
          "courses",
          SECCION_PERMISOS.editar,
          user?.rol?.permissions
        ) && (
          <Tooltip title="Editar" placement="topRight">
            <span style={{ height: "20px" }}>
              <FiEdit3
                className="icono-acciones"
                onClick={(e) => {
                  e.stopPropagation();
                  setDrawerContent({
                    visible: true,
                    type: "Editar Curso",
                    item: item,
                  });
                }}
              />
            </span>
          </Tooltip>
        )}
        {isAvailable(
          "coursesSchedule",
          SECCION_PERMISOS.modulo,
          user?.rol?.permissions
        ) && (
          <Tooltip placement="topLeft" title="Horarios">
            <span
              style={{ height: "20px", cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation();
                setDrawerContent({
                  visible: true,
                  type: "Horarios Curso",
                  item: item,
                });
              }}
            >
              <AiOutlineSchedule size={18} />
            </span>
          </Tooltip>
        )}
        {/* <Popover
          title={
            <div className="content-popover">
              <span className="popover-texto">{`¿Realmente desea desactivar el curso ${item.name}?`}</span>
              <div className="botones-wrapper-content">
                <Button
                  className="btn-guardar"
                  onClick={() => deleteCurso(item)}
                >
                  Desactivar
                </Button>
                <Button onClick={() => setShowPopover(false)}>Cancelar</Button>
              </div>
            </div>
          }
          trigger="click"
          placement="topLeft"
          open={showPopover}
        >
          <span style={{ height: "20px" }}>
            <MdOutlineDeleteOutline
              className="icono-acciones"
              onClick={() => setShowPopover(true)}
            />
          </span>
        </Popover> */}
      </div>
    );
  };

  const columns = [
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Buscar nombre"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={confirm}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            className="btn-guardar"
            onClick={confirm}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Buscar
          </Button>
          <Button
            className="btn-cancelar"
            onClick={() => {
              clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Borrar
          </Button>
        </div>
      ),
      filterIcon: () => (
        <AiOutlineSearch
          style={{
            color: "var(--background-color)",
            fontSize: "16px",
          }}
        />
      ),
      onFilter: (value, record) =>
        record.name.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Descripción",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Duración",
      dataIndex: "duration",
      width: "100px",
      key: "duration",
      render: (dataIndex) => {
        return <span>{`${dataIndex} clases`}</span>;
      },
    },
    {
      title: "Categoría",
      dataIndex: "category",
      key: "category",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Select
            options={[
              { value: "ESTETICA", label: "ESTETICA" },
              { value: "PELUQUERIA", label: "PELUQUERIA" },
              { value: "TODOS", label: "TODOS" },
            ]}
            value={selectedKeys[0]}
            onChange={(e) => {
              if (e === "TODOS") {
                setSelectedKeys([]);
              } else {
                setSelectedKeys(e ? [e] : []);
              }
              confirm();
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
        </div>
      ),
      filterIcon: () => (
        <AiOutlineSearch
          style={{
            color: "var(--background-color)",
            fontSize: "16px",
          }}
        />
      ),
      onFilter: (value, record) =>
        record.category.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Inscriptos",
      dataIndex: "turnsConformation",
      width: "150px",
      key: "turnsConformation",
      render: (dataIndex) => {
        let count = 0;
        dataIndex?.forEach((turn) => {
          count += turn.enrollmentCount;
        });
        return (
          <p className="number-ff number-center">{thousandFormatter(count, true)}</p>
        );
      },
    },
    {
      title: "Acciones",
      dataIndex: "",
      width: "140px",
      key: "",
      render: (dataIndex, item) => <ColumnaAcciones item={item} />,
    },
  ];

  const handleAddCurso = () => {
    setDrawerContent({
      visible: true,
      type: "Agregar Curso",
      item: {},
    });
  };

  const expandedTurnDay = (recordDia) => {
    const columns = [
      {
        title: "Día",
        dataIndex: "day",
        key: "day",
      },
      {
        title: "Desde",
        dataIndex: "startTime",
        key: "startTime",
      },
      {
        title: "Hasta",
        dataIndex: "endTime",
        key: "endTime",
      },
    ];

    const data = recordDia.schedule;

    return (
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        rowKey={(record) =>
          `${record.day}-${record.startTime}-${record.endTime}`
        }
        size="small"
      />
    );
  };

  const expandedRowRender = (record) => {
    const columns = [
      {
        title: "Nombre turno",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Aula",
        dataIndex: "classroomId",
        key: "classroomId",
        render: (data) => {
          return config?.classrooms?.find((element) => element._id === data)
            ?.name;
        },
      },
      {
        title: "Inscriptos",
        dataIndex: "enrollmentCount",
        width: "150px",
        key: "enrollmentCount",
        render: (dataIndex, item) => {
          let cupo =
            config?.classrooms?.find(
              (element) => element._id === item?.classroomId
            )?.maxCapacity || 0;

          return (
            <p className="number-ff number-center">
              {`${thousandFormatter(dataIndex, true)} / ${cupo}`}
            </p>
          );
        },
      },
      {
        title: "Acciones",
        dataIndex: "",
        width: "86px",
        key: "",
        render: (dataIndex, item) => (
          <span className="fila-acciones">
            {isAvailable(
              "coursesConformation",
              SECCION_PERMISOS.modulo,
              user?.rol?.permissions
            )}
            <Tooltip
              placement="left"
              title="Conformación curso"
              mouseEnterDelay={0.15}
            >
              <span
                style={{ height: "18px", cursor: "pointer" }}
                onClick={(e) => {
                  e.stopPropagation();
                  setDrawerContent({
                    visible: true,
                    type: "Conformacion Curso",
                    item: { curso: record, turno: item },
                  });
                }}
              >
                <TbListDetails size={18} />
              </span>
            </Tooltip>
          </span>
        ),
      },
    ];

    const data = record.turnsConformation;

    return (
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        rowKey={"_id"}
        size="small"
        expandable={{
          expandedRowRender: expandedTurnDay,
          columnWidth: "4%",
          expandRowByClick: true,
          showExpandColumn: false,
        }}
      />
    );
  };

  return (
    <div className="modulo-wrapper">
      <div className="modulo-header">
        <p className="modulo-header-titulo">Cursos</p>
        <span className="span-mid-width">
          <Button onClick={() => handleBuscarDisponibilidad()}>
            Buscar disponibilidad
          </Button>
        </span>
        {isAvailable(
          "courses",
          SECCION_PERMISOS.crear,
          user?.rol?.permissions
        ) && <Button onClick={() => handleAddCurso()}>Nuevo curso</Button>}
      </div>
      {showModal && <MainModal setShowModal={setShowModal}></MainModal>}
      <div className="modulo-content">
        {loadingGlobal.cursos ? (
          <BlobLoader />
        ) : (
          <Table
            className="table-wrapper"
            columns={columns}
            dataSource={cursos}
            rowKey={"_id"}
            size="small"
            pagination={{
              pageSize: 10,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} de ${total} cursos`,
              showSizeChanger: false,
            }}
            expandable={{
              expandedRowRender,
              columnWidth: "4%",
              expandRowByClick: true,
              showExpandColumn: false,
            }}
          />
        )}
      </div>
    </div>
  );
};

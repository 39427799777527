import { gql } from "@apollo/client";

export const MANAGE_ACCOUNTING_BOXES = gql`
  mutation ManageUserBoxRelationsResolver(
    $userId: String
    $relationsToActivate: [String]
    $relationsToDeactivate: [String]
  ) {
    manageUserBoxRelationsResolver(
      userId: $userId
      relationsToActivate: $relationsToActivate
      relationsToDeactivate: $relationsToDeactivate
    ) {
      message
      status
    }
  }
`;

export const CLOSE_ACCOUNT_BOX = gql`
  mutation newABCloseResolver($aBHistoryInput: aBHistoryInput) {
    newABCloseResolver(aBHistoryInput: $aBHistoryInput) {
      _id
      dateFrom
      dateTo
      accountBoxId
      status
      amount
    }
  }
`;

import { useLazyQuery, useMutation } from "@apollo/client";
import { Button, Drawer, Popover, Table, Tooltip } from "antd";
import { GET_MOVES_DETAIL } from "../../../../../graphql/query/Cajas";
import { useContext, useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import { GlobalContext } from "../../../../context/GlobalContext";
import { CLOSE_ACCOUNT_BOX } from "../../../../../graphql/mutation/Cajas";
import { PDFViewer } from "@react-pdf/renderer";
import { Recibo } from "../../../reports/components/recibo/Recibo";
import { columnsMovimientosDetalleCierre } from "./utils";
import { ArqueoDeCaja } from "../../../reports/components/cierreCaja/ArqueoDeCaja";
import { arqueoDeCajaData } from "../../../../../utils/arqueoDeCajaData";

export const DetalleCierre = ({
  selectedCierre,
  setSelectedCierre,
  setCajaData,
  cajaData,
}) => {
  const { alumnos, messageApi, config } = useContext(GlobalContext);

  const [getDetail, { data, loading }] = useLazyQuery(GET_MOVES_DETAIL, {
    fetchPolicy: "network-only",
  });

  const [closeAccountBox, { loadingCierre }] = useMutation(CLOSE_ACCOUNT_BOX, {
    onCompleted: (data) => {
      messageApi.success("Caja cerrada correctamente");
      setCajaData((prevState) => {
        if (data?.newABCloseResolver?._id) {
          let obj = structuredClone(prevState);
          let index = obj?.list?.findIndex(
            (element) => element._id === selectedCierre._id
          );

          if (index >= 0) {
            obj.list[index] = data.newABCloseResolver;
          }

          return obj;
        }

        return prevState;
      });
      setSelectedCierre(null);
    },
    onError: (error) => {
      console.log(error);
      messageApi.success("Ocurrió un error al cerrar la caja");
    },
  });

  const [showDrawer, setShowDrawer] = useState(false);

  const [showArqueoDeCaja, setShowArqueoDeCaja] = useState(false);

  const [pdfData, setPdfData] = useState({
    open: false,
    data: null,
  });

  const [showPopoverCierre, setShowPopoverCierre] = useState(false);

  const [dataTable, setDataTable] = useState([]);

  useEffect(() => {
    if (selectedCierre?.accountBoxId) {
      getDetail({
        variables: {
          accountBoxId: selectedCierre.accountBoxId,
          dateFrom: selectedCierre.dateFrom || null,
          dateTo: selectedCierre.dateTo || null,
        },
      });
    }
  }, [selectedCierre]);

  useEffect(() => {
    if (data?.getMovesDetailResolver?.[0]?.moves) {
      let moves = [...data.getMovesDetailResolver[0].moves];
      moves.sort((a, b) => b.date - a.date);
      setDataTable(moves);
    } else {
      setDataTable([]);
    }
  }, [data]);

  const dataCierre = useMemo(() => {
    let obj = {
      name: cajaData.item?.name,
      ...selectedCierre,
    };

    return obj;
  }, [cajaData, selectedCierre]);

  const cerrarCaja = () => {
    if (selectedCierre?.accountBoxId) {
      let obj = {
        accountBoxId: selectedCierre.accountBoxId,
        dateFrom: selectedCierre.dateFrom || null,
        dateTo: Number(dayjs().format("x")),
      };

      closeAccountBox({ variables: { aBHistoryInput: obj } });
    }
  };

  const conceptos = useMemo(() => {
    // Formateo para utilizar la misma funcion que en se utiliza en ModalCajas y NewTransfer.
    const moves = {
      getAccountBoxPayments: {
        moves: dataTable || [],
      },
    };

    return arqueoDeCajaData(moves, config);
  }, [dataTable, config]);

  return (
    <div>
      <Table
        style={{
          marginTop: 20,
          width: "clamp(400px, 90vw, 1000px)",
          overflow: "auto",
        }}
        loading={loading}
        rowKey={"_id"}
        columns={columnsMovimientosDetalleCierre(
          cajaData,
          setPdfData,
          setShowDrawer,
          alumnos,
          setSelectedCierre
        )}
        dataSource={dataTable || []}
        pagination={{
          pageSize: 8,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} de ${total} movimientos`,
          showSizeChanger: false,
        }}
      />
      <span className="horizontal" style={{ marginTop: "12px" }}>
        {selectedCierre.status === "OPEN" && (
          <Popover
            open={showPopoverCierre}
            placement="topLeft"
            content={
              <div className="content-popover">
                <span className="popover-texto">{`¿Realmente desea cerrar la caja?`}</span>
                <div
                  className="botones-wrapper-content"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: 8,
                  }}
                >
                  <Button className="btn-guardar" onClick={cerrarCaja}>
                    Cerrar
                  </Button>
                  <Button onClick={() => setShowPopoverCierre(false)}>
                    Cancelar
                  </Button>
                </div>
              </div>
            }
          >
            <Button
              className="btn-guardar"
              onClick={() => setShowPopoverCierre(true)}
              loading={loadingCierre}
            >
              Cerrar caja
            </Button>
          </Popover>
        )}
        <Button
          className="btn-guardar"
          onClick={() => setShowArqueoDeCaja(true)}
        >
          Arqueo de caja
        </Button>
        <Button
          onClick={() => setSelectedCierre(null)}
          style={{ marginLeft: "auto" }}
          className="btn-cancelar"
        >
          Volver
        </Button>
      </span>
      <Drawer
        open={showArqueoDeCaja}
        width={"calc(100% - 70px)"}
        destroyOnClose
        onClose={(v) => setShowArqueoDeCaja(false)}
      >
        <PDFViewer style={{ width: "100%", height: "100%", border: "none" }}>
          <ArqueoDeCaja
            dataCierre={{ name: cajaData?.item?.name, context: "caja" }}
            dataConceptos={conceptos}
          />
        </PDFViewer>
      </Drawer>
      <Drawer
        open={pdfData.open}
        width={"calc(100% - 70px)"}
        destroyOnClose
        onClose={(v) => setPdfData({ open: false, data: null })}
      >
        <PDFViewer style={{ width: "100%", height: "100%", border: "none" }}>
          <Recibo data={pdfData.data} alumnos={alumnos} />
        </PDFViewer>
      </Drawer>
    </div>
  );
};

// const dataConceptos = useMemo(() => {
//   const groupedByPaymentMethod = dataTable?.reduce((result, item) => {
//     const {name, _id} = item.paymentMethod;

//     if (!result[name]) {
//       let total = 0;
//       if (item.operation?.toUpperCase() === "EGRESO") {
//         total = item.total * -1;
//       } else {
//         total = item.total;
//       }

//       result[name] = {
//         total: total || 0,
//         _id,
//         name,
//       };
//     } else {
//       if (item.operation?.toUpperCase() === "EGRESO") {
//         let total = result[name].total - item.total;

//         result[name].total = total;
//       } else {
//         let total = result[name].total + item.total;

//         result[name].total = total;
//       }
//     }

//     return result;
//   }, {});

//   const groupedArray = Object.values(groupedByPaymentMethod);

//   return groupedArray;
// }, [dataTable, selectedCierre]);

import { ConfigProvider, message } from "antd";
import esES from "antd/es/locale/es_ES";
import { useEffect, useState } from "react";
import "./App.css";
import { GlobalContext } from "./components/context/GlobalContext";
import { AppRouter } from "./components/router/AppRouter";
import { useAlumnos } from "./components/hooks/useAlumnos";
import { useInstructores } from "./components/hooks/useInstructores";
import { handleUser } from "./utils/handleUser";
import { useCursos } from "./components/hooks/useCursos";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  concat,
  ApolloLink,
  HttpLink,
  useSubscription,
} from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { split } from "apollo-link";
import { createClient } from "graphql-ws";
import { useConfig } from "./components/hooks/useConfig";
import dayjs from "dayjs";
import { USER_PERMISSIONS_SUB } from "./graphql/subscription/UserPermissions";
import { handleLogout } from "./utils/handleLogout";
import { handlePermissionsSub } from "./utils/handlePermissionsSub";
// import { GET_VERSION } from "./graphql/query/Version";

import { useUser } from "./components/hooks/useUser";
import customParseFormat from "dayjs/plugin/customParseFormat";
import advancedFormat from "dayjs/plugin/advancedFormat";
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_HTTP_URL,
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.REACT_APP_WS_URL,
  })
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const authMiddleware = new ApolloLink((operation, forward) => {
  let token = "";

  token = localStorage.getItem("token");

  operation.setContext({
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
  return forward(operation);
});

const myClient = new ApolloClient({
  link: concat(authMiddleware, splitLink),
  cache: new InMemoryCache(),
});

const App = () => {
  const [user, setUser] = useState(null);
  const [alumnos, setAlumnos] = useState(null);
  const [instructores, setInstructores] = useState(null);
  const [cursos, setCursos] = useState(null);
  const [config, setConfig] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [drawerContent, setDrawerContent] = useState({
    visible: false,
    type: "",
    item: {},
  });
  const [loadingGlobal, setLoadingGlobal] = useState({
    alumnos: false,
    instructores: false,
    cursos: false,
    config: false,
  });

  const [cursosPoll, setCursosPoll] = useState({});
  const [configPoll, setConfigPoll] = useState({});
  const [profiles, setProfiles] = useState([]);
  const [users, setUsers] = useState([]);
  const [moves, setMoves] = useState([]);
  const [movesPoll, setMovesPoll] = useState({});
  const [filteredMoves, setFilteredMoves] = useState([]);

  const { data, loading, error } = useSubscription(USER_PERMISSIONS_SUB);

  // const {
  //   data: dataVersion,
  //   loading: loadingVersion,
  //   error: errorVersion,
  // } = useQuery(GET_VERSION);

  useAlumnos(setAlumnos, messageApi, setLoadingGlobal, user);
  useInstructores(setInstructores, messageApi, setLoadingGlobal, user);
  useCursos(setCursos, messageApi, setLoadingGlobal, setCursosPoll, user);
  useConfig(setConfig, messageApi, setLoadingGlobal, setConfigPoll, user);
  useUser(user, setUser);

  useEffect(() => {
    setUser(handleUser());
  }, []);

  document.addEventListener("wheel", function (event) {
    if (document.activeElement.type === "number") {
      document.activeElement.blur();
    }
  });

  useEffect(() => {
    handlePermissionsSub(data, user, setUser, messageApi, handleLogout);
  }, [data]);

  return (
    <>
      <GlobalContext.Provider
        value={{
          user,
          setUser,
          alumnos,
          setAlumnos,
          instructores,
          setInstructores,
          cursos,
          setCursos,
          drawerContent,
          setDrawerContent,
          loadingGlobal,
          setLoadingGlobal,
          messageApi,
          contextHolder,
          cursosPoll,
          setCursosPoll,
          config,
          setConfig,
          configPoll,
          setConfigPoll,
          profiles,
          setProfiles,
          users,
          setUsers,
          moves,
          setMoves,
          setMovesPoll,
          movesPoll,
          filteredMoves,
          setFilteredMoves,
        }}
      >
        <div className="App">
          {contextHolder}
          {process.env.REACT_APP_HTTP_URL ===
            "http://localhost:4002/graphql" && (
            <span className="span-testing">TESTING...</span>
          )}
          <AppRouter />
        </div>
      </GlobalContext.Provider>
    </>
  );
};

const Main = () => {
  const customTheme = {
    token: {
      colorPrimary: "#2DACBD",
    },
    components: {
      Select: {
        colorPrimary: "#2DACBD",
      },
    },
  };

  return (
    <ApolloProvider client={myClient}>
      <ConfigProvider locale={esES} theme={customTheme}>
        <App />
      </ConfigProvider>
    </ApolloProvider>
  );
};

export default Main;

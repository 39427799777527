import { useQuery } from "@apollo/client";
import { useContext, useEffect, useMemo, useState } from "react";
import { Button, Modal, Table } from "antd";
import { ModalTransferencias } from "./ModalTransferencias";
import { isAvailable } from "../../../utils/isAvailable";
import { GlobalContext } from "../../context/GlobalContext";
import { BlobLoader } from "../../ui/loader/BlobLoader";
import { GET_TRANSFERS } from "../../../graphql/query/Transferencias.js";
import { SECCION_PERMISOS } from "../../../assets/permisos-template";
import { NewTransfer } from "./NewTransfer";
import { getAccountBox, getPaymentMethod, transfersColumns } from "./utils.jsx";
import "./transferencias.css";
import { ErrorMessage } from "../../ui/error/ErrorMessage.jsx";

export const Transferencias = () => {
  const { user, config } = useContext(GlobalContext);

  const {
    data,
    loading: loadingGet,
    error,
  } = useQuery(GET_TRANSFERS, {
    fetchPolicy: "network-only",
  });

  const [loading, setLoading] = useState(true);
  const [transferencias, setTransferencias] = useState([]);

  const [dataDetalle, setDataDetalle] = useState(null);
  const [showNewTransfer, setShowNewTransfer] = useState({
    open: false,
    type: null,
    item: null,
  });

  const orderedData = useMemo(() => {
    if (!loadingGet) {
      if (transferencias && config?.accountingBoxes) {
        let array = [...transferencias];
        array.sort((a, b) => b.createdAt - a.createdAt);

        array = array.map((item) => {
          return {
            ...item,
            abFrom: getAccountBox(item.abFrom, config.accountingBoxes),
            abTo: getAccountBox(item.abTo, config.accountingBoxes),
            transferInput: item?.transferInput?.map((payment) => {
              return {
                ...payment,
                ...getPaymentMethod(
                  payment?.paymentMethodId,
                  config.paymentMethods
                ),
              };
            }),
          };
        });

        setLoading(false);
        return array;
      }
    }

    return [];
  }, [transferencias, config]);

  useEffect(() => {
    if (
      data?.getTransfersResolver &&
      Array.isArray(data?.getTransfersResolver)
    ) {
      setTransferencias(data?.getTransfersResolver);
    }
  }, [data]);

  if (error) return <ErrorMessage />;

  return (
    <div className="modulo-wrapper">
      <div className="modulo-header">
        <p className="modulo-header-titulo">Transferencias</p>
        <span className="span-mid-width">
          {/* <Select
            style={{ width: "200px" }}
            options={handleOptions()}
            placeholder="Seleccionar caja"
            value={selectedAccountBox}
            loading={loadingCajas}
            onChange={(v) => setSelectedAccountBox(v)}
            showSearch
            filterOption={(input, option) =>
              (option.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
          /> */}
        </span>
        {isAvailable(
          "transfers",
          SECCION_PERMISOS.crear,
          user?.rol?.permissions
        ) && (
          <Button
            onClick={() =>
              setShowNewTransfer({ open: true, type: "new", item: null })
            }
          >
            Nueva transferencia
          </Button>
        )}
      </div>
      <div className="modulo-content">
        {loading ? (
          <BlobLoader />
        ) : (
          <Table
            loading={loading}
            className="table-wrapper"
            columns={transfersColumns(
              setDataDetalle,
              config,
              setShowNewTransfer,
              setTransferencias
            )}
            dataSource={orderedData || []}
            rowKey={"_id"}
            size="small"
            pagination={{
              pageSize: 10,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} de ${total} transferencias`,
              showSizeChanger: false,
            }}
          />
        )}
        {dataDetalle?._id && (
          <ModalTransferencias
            dataDetalle={dataDetalle}
            setDataDetalle={setDataDetalle}
          />
        )}
        <Modal
          open={showNewTransfer.open}
          footer={false}
          title={
            showNewTransfer.type === "edit"
              ? "Editar transferencia"
              : "Nueva transferencia"
          }
          onCancel={() =>
            setShowNewTransfer({ open: false, type: null, item: null })
          }
          className="custom-modal-wrapper"
          destroyOnClose
        >
          <NewTransfer
            showNewTransfer={showNewTransfer}
            setShowNewTransfer={setShowNewTransfer}
            setTransferencias={setTransferencias}
          />
        </Modal>
      </div>
    </div>
  );
};

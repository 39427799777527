import { gql } from "@apollo/client";

export const GET_STUDENTS = gql`
  query GetStudentsResolver {
    getStudentsResolver {
      _id
      fullName
      dni
      nationality
      gender
      birthDay
      email
      medicalRecord
      createdAt
      status
      phoneNumbers {
        phoneNumber
      }
      addressInfo {
        street
        cp
        city
      }
    }
  }
`;

export const GET_STUDENTS_FEES = gql`
  query getStudentsFeesResolver {
    getStudentsFeesResolver {
      _id
      student {
        _id
        courses {
          courseId
          name
          amountOfFees
          startDate
          moves {
            feeDetail {
              fees {
                feeNumber
                value
              }
            }
          }
        }
      }
    }
  }
`;

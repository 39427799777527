import { useContext, useState } from "react";
import { GlobalContext } from "../../../../context/GlobalContext";
import { useMutation } from "@apollo/client";
import { DELETE_MOVE } from "../../../../../graphql/mutation/Finanzas";
import { isAvailable } from "../../../../../utils/isAvailable";
import { SECCION_PERMISOS } from "../../../../../assets/permisos-template";
import { Button, Popover, Tooltip } from "antd";
import { FiEdit3, FiEye } from "react-icons/fi";
import { showEdit } from "../../../finanzas/utils";
import { BiPrinter } from "react-icons/bi";
import { TbTrash } from "react-icons/tb";
import { thousandFormatter } from "../../../../../utils/thousandFormatter";
import dayjs from "dayjs";
import { MAIN_PROFILE_ID } from "../../../../../utils/relevantIds";

export const MovimientosColumnaAccionesDetalleCierre = ({
  item,
  selectedAccountBox,
  setPdfData,
  limitDate,
  loadingAB,
  setShowDrawer,
  setSelectedCierre,
}) => {
  let object = { ...item, accountBoxId: selectedAccountBox };

  const { user, setDrawerContent, messageApi, alumnos } =
    useContext(GlobalContext);

  const [showPopover, setShowPopover] = useState(false);

  const [deleteMoveResolver, { loading }] = useMutation(DELETE_MOVE);

  const deleteMove = (moveId) => {
    deleteMoveResolver({ variables: { moveId: moveId } })
      .then((res) => {
        if (res?.data?.deleteMoveResolver?._id) {
          messageApi.success("Movimiento eliminado correctamente");

          setSelectedCierre((prevState) => {
            return { ...prevState };
          });

          setShowPopover(false);
        } else {
          messageApi.error("Ocurrió un error al eliminar el movimiento");
        }
      })
      .catch((error) => {
        console.log(error);
        messageApi.error("Ocurrió un error al eliminar el movimiento");
      });
  };

  return (
    <div className="fila-acciones">
      {isAvailable("moves", SECCION_PERMISOS.ver, user?.rol?.permissions) && (
        <Tooltip title="Ver detalles" placement="topRight">
          <span className="icon-action-wrapper">
            <FiEye
              className="icono-acciones"
              onClick={() => {
                setDrawerContent({
                  visible: true,
                  type: "Ver Movimiento",
                  item: object,
                });
                setShowDrawer(false);
              }}
            />
          </span>
        </Tooltip>
      )}
      {showEdit(user, limitDate, loadingAB, object) && (
        <Tooltip title="Editar" placement="topRight">
          <span className="icon-action-wrapper">
            <FiEdit3
              className="icono-acciones"
              onClick={() => {
                setDrawerContent({
                  visible: true,
                  type: "Editar Movimiento desde cierre",
                  item: object,
                });
                setShowDrawer(false);
              }}
            />
          </span>
        </Tooltip>
      )}
      {isAvailable("moves", SECCION_PERMISOS.ver, user?.rol?.permissions) && (
        <Tooltip title="Ver recibo" placement="topRight">
          <span className="icon-action-wrapper">
            <BiPrinter
              className="icono-acciones"
              onClick={() => setPdfData({ open: true, data: object })}
            />
          </span>
        </Tooltip>
      )}
      {user?.rol?._id === MAIN_PROFILE_ID && (
        <Popover
          open={showPopover}
          placement="bottomRight"
          content={
            <div className="content-popover" style={{ marginBottom: 0 }}>
              <span className="popover-texto">
                ¿Realmente desea eliminar este movimiento?
              </span>
              <div
                className="botones-wrapper-content"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Button
                  className="btn-guardar"
                  onClick={() => deleteMove(item._id)}
                  loading={loading}
                >
                  Eliminar
                </Button>
                <Button onClick={() => setShowPopover(false)}>Cancelar</Button>
              </div>
            </div>
          }
        >
          <span
            className="icon-action-wrapper"
            onClick={() => setShowPopover(true)}
          >
            <TbTrash className="icono-acciones" />
          </span>
        </Popover>
      )}
    </div>
  );
};

export const columnsMovimientosDetalleCierre = (
  cajaData,
  setPdfData,
  setShowDrawer,
  alumnos,
  setSelectedCierre
) => {
  return [
    {
      title: "Área",
      dataIndex: "area",
      key: "area",
      render: (data) => data?.name,
    },
    {
      title: "Concepto",
      dataIndex: "concept",
      key: "concept",
      render: (data) => data?.name,
    },
    {
      title: "Alumno",
      dataIndex: "studentId",
      key: "studentId",
      render: (data) =>
        alumnos?.find((element) => element._id === data)?.fullName,
    },
    {
      title: "Fecha",
      dataIndex: "date",
      key: "date",
      width: 120,
      render: (data) => {
        if (data) {
          return (
            <p className="number-ff">
              {dayjs(data, "x")?.format("DD/MM/YYYY")}
            </p>
          );
        } else {
          return false;
        }
      },
    },
    {
      title: "Monto",
      dataIndex: "total",
      key: "total",
      align: "right",
      render: (data, item) => (
        <p
          className="number-ff"
          style={
            item.operation
              ? item.operation === "INGRESO"
                ? { color: "#00A150" }
                : { color: "#FF4D48" }
              : { color: "black" }
          }
        >
          {thousandFormatter(data)}
        </p>
      ),
    },
    {
      title: "Acciones",
      dataIndex: "",
      width: "100px",
      key: "",
      render: (dataIndex, item) => {
        return (
          <MovimientosColumnaAccionesDetalleCierre
            item={item}
            selectedAccountBox={cajaData?.item?._id}
            setPdfData={setPdfData}
            setShowDrawer={setShowDrawer}
            setSelectedCierre={setSelectedCierre}
          />
        );
      },
    },
  ];
};

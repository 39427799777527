import { thousandFormatter } from "../../../utils/thousandFormatter.js";
import { FiEye, FiEdit3 } from "react-icons/fi";
import dayjs from "dayjs";
import { AiOutlineSearch } from "react-icons/ai";
import { BiPrinter } from "react-icons/bi";
import { Button, Input, Popover, Tooltip } from "antd";
import { isAvailable } from "../../../utils/isAvailable.js";
import { SECCION_PERMISOS } from "../../../assets/permisos-template.js";
import { useContext, useState } from "react";
import { GlobalContext } from "../../context/GlobalContext.js";
import { TbTrash } from "react-icons/tb";
import { useMutation } from "@apollo/client";
import { DELETE_MOVE } from "../../../graphql/mutation/Finanzas.js";
import { MAIN_PROFILE_ID } from "../../../utils/relevantIds.js";
import { updateArrayMovimientoDelete } from "../../../utils/updateArrayMovimientos.js";

export const getDataByAccountBox = (moves, selectedAccountBox) => {
  if (moves) {
    let filter = moves.find((obj) => obj._id === selectedAccountBox);
    if (filter) {
      filter.moves.sort((a, b) => b.date - a.date);
      return filter.moves;
    } else {
      return [];
    }
  } else {
    return [];
  }
};

export const showEdit = (user, limitDate, loadingAB, object) => {
  if (user?.rol?._id === MAIN_PROFILE_ID) return true;

  if (loadingAB) return false;

  if (object.date <= limitDate) return false;

  return isAvailable("moves", SECCION_PERMISOS.editar, user?.rol?.permissions);
};

const ColumnaAcciones = ({
  item,
  setPdfData,
  limitDate,
  loadingAB,
  modulo,
}) => {
  let object = { ...item, accountBoxId: item?.accountingBox?._id };

  const { user, setDrawerContent, messageApi, setMoves, setFilteredMoves } =
    useContext(GlobalContext);

  const [showPopover, setShowPopover] = useState(false);

  const [deleteMoveResolver, { loading }] = useMutation(DELETE_MOVE, {
    onCompleted: (data) => {
      if (data?.deleteMoveResolver?._id) {
        updateArrayMovimientoDelete(
          data,
          setMoves,
          setFilteredMoves,
          messageApi,
          setShowPopover,
          modulo
        );
      }
    },
    onError: (error) => console.log(error),
  });

  const deleteMove = (moveId) => {
    deleteMoveResolver({ variables: { moveId: moveId } });
  };

  return (
    <div className="fila-acciones">
      {isAvailable("moves", SECCION_PERMISOS.ver, user?.rol?.permissions) && (
        <Tooltip title="Ver detalles" placement="topRight">
          <span className="icon-action-wrapper">
            <FiEye
              className="icono-acciones"
              onClick={() => {
                setDrawerContent({
                  visible: true,
                  type: "Ver Movimiento",
                  item: object,
                });
              }}
            />
          </span>
        </Tooltip>
      )}
      {showEdit(user, limitDate, loadingAB, object) && (
        <Tooltip title="Editar" placement="topRight">
          <span className="icon-action-wrapper">
            <FiEdit3
              className="icono-acciones"
              onClick={() => {
                setDrawerContent({
                  visible: true,
                  type: "Editar Movimiento",
                  item: object,
                });
              }}
            />
          </span>
        </Tooltip>
      )}
      {isAvailable("moves", SECCION_PERMISOS.ver, user?.rol?.permissions) && (
        <Tooltip title="Ver recibo" placement="topRight">
          <span className="icon-action-wrapper">
            <BiPrinter
              className="icono-acciones"
              onClick={() => setPdfData({ open: true, data: object })}
            />
          </span>
        </Tooltip>
      )}
      {/* {user?.rol?._id === MAIN_PROFILE_ID && ( */}
      <Popover
        open={showPopover}
        placement="bottomRight"
        content={
          <div className="content-popover" style={{ marginBottom: 0 }}>
            <span className="popover-texto">
              ¿Realmente desea eliminar este movimiento?
            </span>
            <div
              className="botones-wrapper-content"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button
                className="btn-guardar"
                onClick={() => deleteMove(item._id)}
                loading={loading}
              >
                Eliminar
              </Button>
              <Button onClick={() => setShowPopover(false)}>Cancelar</Button>
            </div>
          </div>
        }
      >
        <span
          className="icon-action-wrapper"
          onClick={() => setShowPopover(true)}
        >
          <TbTrash className="icono-acciones" />
        </span>
      </Popover>
      {/* )} */}
    </div>
  );
};

const getAccountLimitDate = (cierres) => {
  try {
    if (cierres?.length > 0) {
      let reversedCierres = [...cierres].reverse();
      if (reversedCierres?.[0]?.dateTo) {
        return reversedCierres?.[0].dateTo;
      }
    }

    return 0;
  } catch (error) {
    console.log(error);
    return 0;
  }
};

export const columnMovimientos = (
  setPdfData,
  alumnos,
  dataAB,
  loadingAB,
  modulo
) => {
  const limitDate = getAccountLimitDate(dataAB?.getABHistoryResolver);

  return [
    {
      title: "Área",
      dataIndex: "area",
      key: "area",
      render: (data) => data?.name,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Buscar área"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={confirm}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            className="btn-guardar"
            onClick={confirm}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Buscar
          </Button>
          <Button
            className="btn-cancelar"
            onClick={() => {
              clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Borrar
          </Button>
        </div>
      ),
      filterIcon: () => (
        <AiOutlineSearch
          style={{
            color: "var(--background-color)",
            fontSize: "16px",
          }}
        />
      ),
      onFilter: (value, record) => {
        return record.area?.name?.toLowerCase()?.includes(value.toLowerCase());
      },
    },
    {
      title: "Caja",
      dataIndex: "accountingBox",
      key: "accountingBox",
      render: (data) => {
        return data?.name || "";
      },
    },
    {
      title: "Concepto",
      dataIndex: "concept",
      key: "concept",
      render: (data) => data?.name,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Buscar concepto"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={confirm}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            className="btn-guardar"
            onClick={confirm}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Buscar
          </Button>
          <Button
            className="btn-cancelar"
            onClick={() => {
              clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Borrar
          </Button>
        </div>
      ),
      filterIcon: () => (
        <AiOutlineSearch
          style={{
            color: "var(--background-color)",
            fontSize: "16px",
          }}
        />
      ),
      onFilter: (value, record) => {
        return record.concept?.name
          ?.toLowerCase()
          ?.includes(value.toLowerCase());
      },
    },
    {
      title: "Alumno",
      dataIndex: "studentId",
      key: "studentId",
      render: (data) =>
        alumnos?.find((element) => element._id === data)?.fullName,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Buscar alumno"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={confirm}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            className="btn-guardar"
            onClick={confirm}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Buscar
          </Button>
          <Button
            className="btn-cancelar"
            onClick={() => {
              clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Borrar
          </Button>
        </div>
      ),
      filterIcon: () => (
        <AiOutlineSearch
          style={{
            color: "var(--background-color)",
            fontSize: "16px",
          }}
        />
      ),
      onFilter: (value, record) => {
        return alumnos
          ?.find((element) => element._id === record.studentId)
          ?.fullName?.toLowerCase()
          ?.includes(value.toLowerCase());
      },
    },
    {
      title: "Descripción",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Fecha",
      dataIndex: "date",
      key: "date",
      width: 90,
      render: (data) => {
        if (data) {
          return (
            <p className="number-ff">
              {dayjs(data, "x")?.format("DD/MM/YYYY")}
            </p>
          );
        } else {
          return false;
        }
      },
    },
    {
      title: "Monto",
      dataIndex: "total",
      key: "total",
      align: "right",
      render: (data, item) => (
        <p
          className="number-ff"
          style={
            item.operation
              ? item.operation === "INGRESO"
                ? { color: "#00A150" }
                : { color: "#FF4D48" }
              : { color: "black" }
          }
        >
          {thousandFormatter(data)}
        </p>
      ),
    },
    {
      title: "Acciones",
      dataIndex: "",
      width: "100px",
      key: "",
      render: (dataIndex, item) => (
        <ColumnaAcciones
          item={item}
          setPdfData={setPdfData}
          limitDate={limitDate}
          loadingAB={loadingAB}
          modulo={modulo}
        />
      ),
    },
  ];
};

export const filterOption = (input, option) =>
  (option.label ?? "").toLowerCase().includes(input.toLowerCase());

import { Button, Form, Input, Pagination, Tooltip } from "antd";
import { useContext, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { GlobalContext } from "../../../context/GlobalContext";
import { isAvailable } from "../../../../utils/isAvailable";
import { SECCION_PERMISOS } from "../../../../assets/permisos-template";
import { useMutation } from "@apollo/client";
import { CREATE_ACCOUNTING_BOX } from "../../../../graphql/mutation/Config";
import { AiOutlineTool } from "react-icons/ai";
import { BlobLoader } from "../../../ui/loader/BlobLoader";
import { ModalCajas } from "./cierreCajas/ModalCajas";

export const Cajas = () => {
  const { config, messageApi, user, configPoll, loadingGlobal } =
    useContext(GlobalContext);
  const [busqueda, setBusqueda] = useState("");
  const [pagina, setPagina] = useState(1);

  const [cajaData, setCajaData] = useState({
    open: false,
    item: null,
    list: [],
  });

  const [form] = Form.useForm();

  const SIZE = 21;

  const [newAccountingBoxResolver, { data, loading, error }] = useMutation(
    CREATE_ACCOUNTING_BOX,
    {
      onCompleted: (data) => {
        if (data?.newAccountingBoxResolver?.status === 200) {
          messageApi.success("Caja agregada correctamente");
          form.setFieldsValue({
            name: "",
            description: "",
          });

          configPoll.start(500);
          setTimeout(() => {
            configPoll.stop();
          }, 1000);
        } else {
          messageApi.error("Ocurrió un error al agregar una caja");
        }
      },
      onError: (error) => {
        messageApi.error("Ocurrió un error al agregar una caja");
      },
    }
  );

  const paginado = (config) => {
    let array = [];

    if (config?.accountingBoxes) {
      array = [...config.accountingBoxes];

      if (busqueda) {
        array = array.filter((element) => {
          if (element.name?.toLowerCase().includes(busqueda.toLowerCase())) {
            return true;
          } else {
            return false;
          }
        });
      }

      let number = (pagina - 1) * SIZE;
      if (array.length > 0) {
        array = array.slice(number, number + SIZE);
      }
    }

    return array;
  };

  const onFinish = (v) => {
    let name = v.name?.trim().toUpperCase() || "";
    let description = v.description?.trim() || "";

    if (name.length < 3) {
      return messageApi.info("El nombre debe contener al menos 3 caracteres");
    }

    newAccountingBoxResolver({
      variables: {
        accountingBoxInput: { name: name, description: description },
      },
    });
  };

  return (
    <div className="config-layout">
      {cajaData?.item?._id && (
        <ModalCajas cajaData={cajaData} setCajaData={setCajaData} />
      )}
      <div className="config-column">
        <div className="config-table-wrapper">
          <span className="config-tabla-header">
            <p className="config-new-title" style={{ marginBottom: "8px" }}>Lista de cajas</p>
            <Input
              type="search"
              addonAfter={<FiSearch style={{ cursor: "pointer" }} />}
              value={busqueda}
              onChange={(v) => setBusqueda(v.target.value)}
              placeholder="Buscar cajas"
            />
          </span>
          {loadingGlobal?.config ? (
            <BlobLoader />
          ) : (
            <>
              <div className="column-ciudades">
                {paginado(config)?.map((element) => {
                  return (
                    <span className="caja-wrapper" key={element._id}>
                      <p>{element.name}</p>
                      <Tooltip title="Gestionar">
                        <span
                          className="horizontal"
                          style={{
                            marginLeft: "auto",
                            width: "fit-content",
                            cursor: "pointer",
                          }}
                        >
                          <AiOutlineTool
                            size={18}
                            onClick={() =>
                              setCajaData({ open: true, item: element })
                            }
                          />
                        </span>
                      </Tooltip>
                    </span>
                  );
                })}
              </div>
              <Pagination
                style={{ marginLeft: "auto", marginTop: "4px" }}
                value={pagina}
                total={config?.accountingBoxes?.length}
                showSizeChanger={false}
                size="small"
                pageSize={SIZE}
                onChange={(v) => setPagina(v)}
              />
            </>
          )}
        </div>
      </div>
      <div className="config-column">
        {isAvailable(
          "settings",
          SECCION_PERMISOS.crear,
          user?.rol?.permissions
        ) && (
          <div className="config-table-wrapper">
            <p className="config-new-title">Nueva caja</p>
            <Form
              form={form}
              layout="vertical"
              style={{ width: "100%" }}
              onFinish={(v) => onFinish(v)}
            >
              <Form.Item label="Nombre" name="name">
                <Input />
              </Form.Item>
              <Form.Item label="Descripción" name="description">
                <Input.TextArea autoSize={{ minRows: 2 }} />
              </Form.Item>
              <Form.Item className="form-custom-footer">
                <div className="botones-wrapper-content">
                  <Button
                    htmlType="submit"
                    className="btn-guardar"
                    size="large"
                    loading={loading}
                  >
                    Guardar
                  </Button>
                  <Button
                    className="btn-cancelar"
                    size="large"
                    onClick={() => {}}
                  >
                    Cancelar
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        )}
      </div>
    </div>
  );
};

import { Button, Drawer, Modal, Table } from "antd";
import dayjs from "dayjs";
import { FiX } from "react-icons/fi";
import { useContext, useMemo, useState } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import { thousandFormatter } from "../../../utils/thousandFormatter";
import { GlobalContext } from "../../context/GlobalContext";
import { ArqueoDeCaja } from "../reports/components/cierreCaja/ArqueoDeCaja";

const columns = () => {
  return [
    {
      title: "Método de pago",
      dataIndex: "name",
      key: "name",
      render: (data) => data,
    },
    {
      title: "Monto",
      dataIndex: "amount",
      key: "amount",
      align: "right",
      render: (data) => (
        <p
          className="number-ff"
          style={data >= 0 ? { color: "#00A150" } : { color: "#FF4D48" }}
        >
          {thousandFormatter(data)}
        </p>
      ),
    },
  ];
};

export const ModalTransferencias = ({ dataDetalle, setDataDetalle }) => {
  const {} = useContext(GlobalContext);

  const [showDrawer, setShowDrawer] = useState(false);

  return (
    <Modal
      open={dataDetalle?._id}
      className="custom-modal-wrapper"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      closeIcon={<FiX onClick={() => setDataDetalle(null)} />}
      footer={false}
    >
      <>
        {dataDetalle?._id && (
          <span className="horizontal" style={{ gap: 16 }}>
            <span
              className="horizontal"
              style={{ width: "fit-content", gap: 4 }}
            >
              <p>Origen:</p>
              <b>{dataDetalle.abFrom?.name}</b>
            </span>
            <span
              className="horizontal"
              style={{ width: "fit-content", gap: 4 }}
            >
              <p>Destino:</p>
              <b>{dataDetalle.abTo?.name}</b>
            </span>
            <span
              className="horizontal"
              style={{ width: "fit-content", gap: 4 }}
            >
              <p>Fecha:</p>
              <b className="number-ff">
                {dayjs(dataDetalle.createdAt, "x")?.format("DD/MM/YYYY")}
              </b>
            </span>
          </span>
        )}
        {dataDetalle ? (
          <Table
            style={{
              marginTop: 20,
              width: "clamp(400px, 90vw, 800px)",
            }}
            // loading={loading}
            columns={columns()}
            dataSource={dataDetalle?.transferInput || []}
            rowKey={"paymentMethodId"}
            pagination={{
              pageSize: 10,
              showSizeChanger: false,
              // showTotal: (total, range) =>
              //   `${range[0]}-${range[1]} de ${total} movimientos`,
            }}
            // footer={(data) => {
            //   return <span>{`Total: $${dataDetalle.amount}`}</span>;
            // }}
          />
        ) : (
          <></>
        )}
      </>
      <span className="horizontal" style={{ marginTop: 16 }}>
        {/* <Button className="btn-guardar" onClick={() => setShowDrawer(true)}>
          Arqueo de caja
        </Button> */}
        <Button
          onClick={() => setDataDetalle(null)}
          style={{ marginLeft: "auto" }}
          className="btn-cancelar"
        >
          Volver
        </Button>
      </span>
      {/* <Drawer
        open={showDrawer}
        width={"calc(100% - 70px)"}
        destroyOnClose
        onClose={(v) => setShowDrawer(false)}
      >
        <PDFViewer style={{ width: "100%", height: "100%", border: "none" }}>
          <ArqueoDeCaja
            dataCierre={dataDetalle}
            // dataConceptos={dataConceptos}
          />
        </PDFViewer>
      </Drawer> */}
    </Modal>
  );
};

import { gql } from "@apollo/client";

export const LOGIN_AUTH = gql`
  mutation loginAuthentication($loginAuthInput: loginAuthInput) {
    loginAuthenticationResolver(loginAuthInput: $loginAuthInput) {
      message
      status
    }
  }
`;
